import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./ApiSlice/authSlice";
import patientSlice from "./ApiSlice/patientSlice";
import ContactUSSlice from "./ApiSlice/ContactUSSlice";
import UserSlice from "./ApiSlice/UserSlice";
import callSlice from "./ApiSlice/callSlice";
import familySlice from "./ApiSlice/familySlice";
import F1PackageSlice from "./ApiSlice/F1PackageSlice";
import B1B2PackageSlice from "./ApiSlice/B1B2PackageSlice";
import DashBoardSlice from "./ApiSlice/DashBoardSlice";
import F1VisaTrainingSlice from "./ApiSlice/F1VisaTrainingSlice";
import F1VisaMockSlice from "./ApiSlice/F1VisaMockSlice";
import TrainingPlanSlice from "./ApiSlice/TrainingPlanSlice";

const reducers = combineReducers({
  auth: authSlice,
  patient: patientSlice,
  ContactUS: ContactUSSlice,
  user: UserSlice,
  call: callSlice,
  family: familySlice,
  f1Packages: F1PackageSlice,
  b1Packages: B1B2PackageSlice,
  f1VisaTraining: F1VisaTrainingSlice,
  f1VisaMock: F1VisaMockSlice,
  dashboard: DashBoardSlice,
  trainingPlan: TrainingPlanSlice
});

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "patient", "call", "family"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // devTools: false,
});

export const persistor = persistStore(store);
export default store;
