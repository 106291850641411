import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del } from "api/base";
import { get, post, put } from "api/base";  // Adjust the import for API methods
import { getSession } from "helper/authHelper";
import { roles } from "../../utils/commonStrings";

// Initial state for F1 packages
const initialState = {
  f1PackageLoader: false,
  f1PackagesInfo: [],
  totalF1Packages: 0,
};

// Get the user session to determine role
const userInfo = getSession();

// Thunks for API calls
export const GetAllF1Packages = createAsyncThunk(
  "/admin/get-all-f1-packages",
  async ({ page = 0, limit = 0, search }) => {
    try {
      const url = userInfo?.role === roles?.ADMIN || roles?.STAFF ? `/admin/get-all-f1-packages?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}` : `/f1-package/get-all-f1-package?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}`;
      const response = await get(url);
      if (response?.data) {
        return response.data; // Returning the response data if exists
      } else {
        throw new Error("Data not found in response");
      }
    } catch (e) {
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

export const updateF1Package = createAsyncThunk("/admin/update-package/f1", async (body) => {
  try {
    const response = await put(`/admin/update-package/f1/${body?.id}`, body?.data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteF1Package = createAsyncThunk("/admin/delete-f1-package", async (body) => {
  const url = userInfo?.role === roles?.ADMIN || roles?.STAFF && `/admin/delete-f1-package/${body}`;
  try {
    const response = await del(url);
    return response;
  } catch (e) {
    return e.response;
  }
});

// Slice for F1 packages
export const F1PackageSlice = createSlice({
  name: "f1Packages",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllF1Packages.pending, (state) => {
        state.f1PackageLoader = true;
      })
      .addCase(GetAllF1Packages.fulfilled, (state, action) => {
        state.f1PackageLoader = false;
        state.f1PackagesInfo = action.payload?.data?.packages || []; 
        state.totalF1Packages = action.payload?.data?.total_packages || 0; 
      })
      .addCase(GetAllF1Packages.rejected, (state, action) => {
        state.f1PackageLoader = false;
      })
      .addCase(updateF1Package.pending, (state) => {
        state.f1PackageLoader = true;
      })
      .addCase(updateF1Package.fulfilled, (state, action) => {
        state.f1PackageLoader = false;
      })
      .addCase(updateF1Package.rejected, (state) => {
        state.f1PackageLoader = false;
      })
      .addCase(deleteF1Package.pending, (state) => {
        state.f1PackageLoader = true;
      })
      .addCase(deleteF1Package.fulfilled, (state, action) => {
        state.f1PackageLoader = false;
      })
      .addCase(deleteF1Package.rejected, (state) => {
        state.f1PackageLoader = false;
      });
  },
});

// Export the reducer for use in the store
export default F1PackageSlice.reducer;
