import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { put } from "api/base";
import { del } from "api/base";
import { get } from "api/base";
import { post } from "api/base";
import { authHeader } from "helper/authHelper";
const initialState = {
  trainingPlanLoader: false,
  totalTrainingPlans: 0,
  trainingPlanInfo: [],
};

export const getAllTrainingPlans = createAsyncThunk(
  "admin/get-all-plans",
  async () => {
    try {
      const url = `admin/get-all-plans`;
      const response = await get(url);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const createTrainingPlan = createAsyncThunk("admin/create-plan", async (body) => {
  try {
    const response = await post(`admin/create-plan`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const updateTrainingPlan = createAsyncThunk("/admin/update-training-plan", async (body) => {
  try {
    const response = await put(`admin/update-plan/${body?.id}`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteTrainingPlan = createAsyncThunk("/admin/delete-plan", async (body) => {
  try {
    const response = await del(`admin/delete-plan/${body}` );
    return response;
  } catch (e) {
    return e.response;
  }
});

export const TrainingPlanSlice = createSlice({
  name: "trainingPlan",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllTrainingPlans.pending, (state) => {
        state.trainingPlanLoader = true;
      })
      .addCase(getAllTrainingPlans.fulfilled, (state, action) => {
        state.trainingPlanLoader = false;
        state.trainingPlanInfo = action.payload?.data?.plans || [];
        state.totalTrainingPlans = action.payload?.data?.total_plans || [];
      })
      .addCase(getAllTrainingPlans.rejected, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(createTrainingPlan.pending, (state) => {
        state.trainingPlanLoader = true;
      })
      .addCase(createTrainingPlan.fulfilled, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(createTrainingPlan.rejected, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(updateTrainingPlan.pending, (state) => {
        state.trainingPlanLoader = true;
      })
      .addCase(updateTrainingPlan.fulfilled, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(updateTrainingPlan.rejected, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(deleteTrainingPlan.pending, (state) => {
        state.trainingPlanLoader = true;
      })
      .addCase(deleteTrainingPlan.fulfilled, (state, action) => {
        state.trainingPlanLoader = false;
      })
      .addCase(deleteTrainingPlan.rejected, (state, action) => {
        state.trainingPlanLoader = false;
      });
  },
});

export default TrainingPlanSlice.reducer;
