import { clsx } from "clsx";
import SoftTypography from "components/SoftTypography";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const validateField = (field, value) => {
  if (!value?.trim()) {
    const formattedField = field
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return `${formattedField} is required.`;
  }
  return ""; 
};

export const formatTypography = (content) => (
  <SoftTypography variant="caption" color="black" fontWeight="medium">
      {content || "-"}
  </SoftTypography>
);
