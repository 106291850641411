import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import SoftBox from "components/SoftBox";
import { Icon } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { Person, SupportAgent } from "@mui/icons-material";
import { getSession } from "helper/authHelper";

function DashboardNavbar({ absolute = false, light = false, isMini = false }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, false || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const userInfo = getSession();
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <AppBar
      position={pathname !== "/profile" ? "sticky" : "absolute"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox
          sx={{
            display: "flex",
            flexDirection: isMini ? "row" : "row",
            justifyContent: isMini ? "flex-start" : "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SoftBox
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: isMini ? "16px" : 0,
            }}
          >
            <Person style={{ marginRight: "5px" }} />
            <SoftTypography
              variant="caption"
              color="secondary"
              fontWeight="bold"
              fontSize="16px"
              component="h1"
            >
              {userInfo?.username.toUpperCase()}
            </SoftTypography>
          </SoftBox>

          {!isMini && (
            <SoftBox
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SupportAgent color="primary" style={{ marginRight: "4px" }} />
              <SoftTypography
                variant="caption"
                color="secondary"
                fontWeight="bold"
                fontSize="16px"
                component="h1"
              >
                {userInfo?.role
                  ?.split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </SoftTypography>
              <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                  <SoftBox color={light ? "white" : "inherit"}>
                    <IconButton
                      size="small"
                      color="inherit"
                      sx={navbarMobileMenu}
                      onClick={handleMiniSidenav}
                    >
                      <Icon className={light ? "text-white" : "text-dark"}>
                        {miniSidenav ? "menu_open" : "menu"}
                      </Icon>
                    </IconButton>
                  </SoftBox>
            </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

