import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Pagination, Tooltip } from "@mui/material";
import "./patient.css";
import { Clear, Delete, Edit } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { F1_PACKAGES_COLUMNS } from "helper/constant";
import useDebounce from "helper/useDebounce";
import { deleteF1Package, GetAllF1Packages, updateF1Package } from "../../redux/ApiSlice/F1PackageSlice";
import PackageUpadtaionModal from "./model/packageUpdationModal";
import { formatTypography, validateField } from "../../utils/index";
import { CURRENT_EDUCATION_LEVEL } from "helper/constant";
import { SPONSERSHIP } from "helper/constant";
import { CHAT_APP } from "helper/constant";

const defaultFormData = {
  name: "",
  email: "",
  current_education_level: "",
  intended_major: "",
  sponsorship: "",
  country: "",
  phone_number: "",
  chat_app: "",
  chat_app_id: "",
};

function F1PackageComponent() {
  document.title = "Prodigy-chap | F1 Packages";
  const { f1PackagesInfo, totalF1Packages, f1PackageLoader } = useSelector(
    (state) => state.f1Packages
  );
  const [openDeleteF1Modal, setOpenDeleteF1Modal] = useState(false);
  const [deleteF1Id, setDeleteF1Id] = useState(null);
  const [openUpadteF1Modal, setOpenUpdateF1Modal] = useState(false);
  const [updateF1PackageData, setUpdateF1PackageData] = useState({ id: "", data: {} });
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const currentRows = useMemo(() => f1PackagesInfo?.map((item, index) => ({
      "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
      "date": formatTypography(new Date(item?.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'numeric',
        year: 'numeric',
      })),
      "name": formatTypography(item?.name),
      "email": formatTypography(item?.email),
      "current education": formatTypography(item?.current_education_level),
      "intended major": formatTypography(item?.intended_major),
      "sponsorship": formatTypography(item?.sponsorship),
      "country": formatTypography(item?.country),
      "phone": formatTypography(item?.phone_number),
      "chat app": formatTypography(item?.chat_app),
      "chat id": formatTypography(item?.chat_app_id),
      "action": (
        <Box sx={{ gap: "10px", display: "flex" }}>
          {" "}
          <Tooltip title="Edit" placement="top">
            <Icon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleEdit(item?.id, item);
              }}
              fontSize="1px"
              style={{ fontSize: "18px" }}
            >
              <Edit />
            </Icon>
          </Tooltip>{" "}
          <Tooltip title="Delete" placement="top">
            <Icon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleDeleteF1Confirm(item?.id);
              }}
              fontSize="1px"
              style={{ fontSize: "18px" }}
            >
              <Delete />
            </Icon>
          </Tooltip>
        </Box>
      ),
    })), [f1PackagesInfo, currentPage, pageSize]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(
        GetAllF1Packages({
          search: debounceSearch,
          page: currentPage,
          limit: pageSize,
        })
      );
    } catch (error) {}
  }, [dispatch, debounceSearch, currentPage, pageSize]);

  const handleClose = (isDelete) => {
    if (isDelete) {
      setOpenDeleteF1Modal(false);
    } else {
      setOpenUpdateF1Modal(false);
    }
  };

  const handleEdit = (id, data) => {
    setFormData({
      name: data?.name || "",
      email: data?.email || "",
      current_education_level: data?.current_education_level || "",
      intended_major: data?.intended_major || "",
      sponsorship: data?.sponsorship || "",
      country: data?.country || "",
      phone_number: data?.phone_number || "",
      chat_app: data?.chat_app || "",
      chat_app_id: data?.chat_app_id || "",
    });
    setUpdateF1PackageData({ id, data: formData });
    setOpenUpdateF1Modal(true);
  };

  const handleDeleteF1Confirm = (id) => {
    setDeleteF1Id(id);
    setOpenDeleteF1Modal(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteF1Id) {
      const response = await dispatch(deleteF1Package(deleteF1Id));
      setOpenDeleteF1Modal(false);
      if (response?.payload?.data?.success) {
        toast.success(`F1 Package deleted successfully`);
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }
  };

  const handleChange = (e, name) => {
    const { target } = e;
    const key = name || target.name;
    const value = target?.value || e.value; 
    setFormData((prev) => ({ ...prev, [key]: value }));

    if (errors[`${key}Err`]) {
      setErrors((prev) => ({ ...prev, [`${key}Err`]: "" }));
    }
  };


  const handleUpdateSubmit = async () => {
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) newErrors[`${key}Err`] = error;
    });
  
    if (!formData.name) newErrors.nameErr = "Name is required.";
    if (!formData.email) newErrors.emailErr = "Email is required.";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await dispatch(updateF1Package({
        id: updateF1PackageData.id,
        data: formData
      }));

      if (response?.payload?.success) {
        setOpenUpdateF1Modal(false);
        toast.success(response?.payload?.message);
        fetchData();
      } else {
        toast.success(SOMETHING_WRONG);
        setOpenUpdateF1Modal(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">F1 Packages List</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search name, email or phone"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value?.trimStart());
                      setCurrentPage(1);
                    }}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white"
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <div className="table-size table-sticky-page-data">
                <Table
                  columns={F1_PACKAGES_COLUMNS}
                  rows={currentRows}
                  text={"f1 packages"}
                  loader={f1PackageLoader}
                />
              </div>
            </SoftBox>
          </Card>
        </SoftBox>
        {totalF1Packages > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalF1Packages / pageSize)} // Total pages calculated from totalF1Packagess
                page={currentPage} // Current page
                onChange={handlePageChange} // Handle page change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      {
        openUpadteF1Modal && <PackageUpadtaionModal
          open={openUpadteF1Modal}
          title="Update F1 Package"
          formData={formData}
          fields={[
            { name: "name", label: "Name", type: "text", placeholder: "Enter name", required: true },
            { name: "email", label: "Email", type: "text", placeholder: "Enter email", required: true },
            { name: "current_education_level", options: CURRENT_EDUCATION_LEVEL, label: "Education", type: "dropdown", placeholder: "Enter education", required: true },
            { name: "intended_major", label: "Intended Major", type: "text", placeholder: "Enter intended major", required: true },
            { name: "sponsorship", options: SPONSERSHIP, label: "Sponsorship", type: "dropdown", placeholder: "Enter sponsorship details", required: true },
            { name: "country", label: "Country", type: "countryDropdown", isCountryDropdown: true, required: true },
            { name: "phone_number", label: "Phone", type: "phone", placeholder: "Enter phone number", required: true },
            { name: "chat_app", options: CHAT_APP, label: "Chat App", type: "dropdown", placeholder: "Enter chat app", required: true },
            { name: "chat_app_id", label: "Chat ID", type: "text", placeholder: "Enter chat ID", required: true },
          ]}
          errors={errors}
          onClose={() => handleClose(false)}
          onChange={handleChange}
          onSubmit={handleUpdateSubmit}
        />
      }


      {openDeleteF1Modal && (
        <ConfirmationModel
          open={openDeleteF1Modal}
          title="Are you sure?"
          description={`You want to delete this F1 Package?`}
          buttonContent={"Delete"}
          closeHandler={() => handleClose(true)}
          confirmHandler={handleConfirmDelete}
        />
      )}
    </DashboardLayout>
  );
}

export default F1PackageComponent;
