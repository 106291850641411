import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import SoftBadge from "components/SoftBadge";
import active from "../../assets/images/active.png";
import deactive from "../../assets/images/deactive.png";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Modal, Pagination, Tooltip, Typography } from "@mui/material";
import "./patient.css";
import SoftButton from "components/SoftButton";
import { Add, Clear, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Close from "@mui/icons-material/Close";
import SoftInput from "components/SoftInput";
import {
  createStaff,
  deactiveStaff,
  deleteStaff,
  GetAllStaffs,
  updateStaff,
} from "../../redux/ApiSlice/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { EMAIL_REGEX } from "helper/constant";
import { SOMETHING_WRONG } from "helper/constant";
import { STAFF_LIST_COLUMNS } from "helper/constant";
import useDebounce from "helper/useDebounce";
import StaffModal from "./model/StaffModal";
import { formatTypography } from "../../utils/index";

function Staff() {
  document.title = "Prodigy-chap | Staff";
  const { staffInfo, totalStaff, staffLoader } = useSelector((state) => state.patient);
  const [addForm, setAddForm] = useState({ userName: "", email: "" });
  const [updateForm, setUpdateForm] = useState({ email: "" });
  const [errorAddStaff, setErrorAddStaff] = useState({});
  const [errorUpdateStaff, setErrorUpdateStaff] = useState({});
  const [openDeactiveModel, setOpenDeactiveModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteStaffId, setDeleteStaffId] = useState(null);
  const [updateStaffIdAndEmail, setUpdateStaffIdAndEmail] = useState({});
  const [staffIdStatus, setStaffIdStatus] = useState({ id: "", status: "" });
  const userNameRef = useRef();
  const emailRef = useRef();
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [openUpdateStaff, setOpenUpdateStaff] = useState(false);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const currentRows = useMemo(() => staffInfo?.map((item, index) => ({
    "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
    "name": formatTypography(item?.username),
    "email": formatTypography(item?.email),
    "status":(
      <SoftBadge
        variant="gradient"
        badgeContent={item.is_active === true ? "Active" : "Deactive"}
        color={item.is_active === true ? "primary" : "secondary"}
        size="xs"
        container
      />
    ),
    "action": (
      <Box sx={{ gap: "10px", display: "flex" }}>
        {" "}
        <Tooltip title="Edit" placement="top">
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleEdit(item?.id, item?.email);
            }}
            fontSize="1px"
            style={{ fontSize: "18px" }}
          >
            <Edit />
          </Icon>
        </Tooltip>{" "}
        <Tooltip title="Delete" placement="top">
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleAction("delete", item?.id)
            }}
            fontSize="1px"
            style={{ fontSize: "18px" }}
          >
            <Delete />
          </Icon>
        </Tooltip>{" "}
        <Tooltip title={item.is_active === true ? "Deactive" : "Active"} placement="top">
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleAction("deactivate", item?.id, item?.is_active)
            }}
            fontSize="1px"
            style={{ fontSize: "18px" }}
          >
            {item.is_active === true ? (
              <img src={deactive} alt="Deactive icon" />
            ) : (
              <img src={active} alt="active icon" />
            )}
          </Icon>
        </Tooltip>
      </Box>
    ),
  })), [staffInfo, currentPage, pageSize]);

  const fetchData = useCallback(() => {
    dispatch(
      GetAllStaffs({ search: debounceSearch, page: currentPage, limit: pageSize })
    );
  }, [debounceSearch, currentPage, pageSize, dispatch]);
  
  const handleEdit = useCallback((id, email) => {
    setUpdateStaffIdAndEmail(id, email);
    setUpdateForm({email: email});
    setOpenUpdateStaff(true);
  }, [])

  const handleModalClose = (modalType) => {
    if (modalType === "addStaff") setOpenAddStaff(false);
    if (modalType === "updateStaff") setOpenUpdateStaff(false);
    if (modalType === "deactive") setOpenDeactiveModel(false);
    if (modalType === "delete") setOpenDeleteModel(false);
  };

  const handleChange = useCallback((e, isUpdate) => {
    const { name, value } = e.target;
    if (isUpdate) {
      setUpdateForm((prev) => ({ ...prev, [name]: value }));
      setErrorUpdateStaff((prev) => ({ ...prev, [`${name}Err`]: "" }));
    } else {
      setAddForm((prev) => ({ ...prev, [name]: value }));
      setErrorAddStaff((prev) => ({ ...prev, [`${name}Err`]: "" }));
    }
  }, []);

  const handleAction = (type, id, status = null) => {
    switch (type) {
      case "delete":
        setOpenDeleteModel(true);
        setDeleteStaffId(id);
        break;
      case "deactivate":
        setOpenDeactiveModel(true);
        setStaffIdStatus({ id, status });
        break;
      default:
        break;
    }
  };
  
  const handleConfirmAction = async (actionType) => {
    if (actionType === "deactivate" && staffIdStatus?.id) {
      const response = await dispatch(
        deactiveStaff({
          id: staffIdStatus.id,
          is_active: !staffIdStatus.status,
        })
      );
      
      setOpenDeactiveModel(false);
      if (response?.payload?.data?.success) {
        toast.success(`Staff ${staffIdStatus.status ? "deactivated" : "activated"} successfully`);
        setAddForm({ userName: "", email: "" });
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    } else if (actionType === "delete" && deleteStaffId) {
      const response = await dispatch(deleteStaff(deleteStaffId));
      
      setOpenDeleteModel(false);
      if (response?.payload?.data?.success) {
        toast.success("Staff deleted successfully");
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }
  };
  
  const validateForm = (formData) => {
    const errors = {};
    if (!EMAIL_REGEX.test(formData.email)) errors.emailErr = "Invalid email format";
    if (!formData.email) errors.emailErr = "Email is required";
    if (!formData.userName || formData.userName.length < 3) errors.userNameErr = "Name must be at least 3 characters long";
    return errors;
  };  

  const handleSubmit = (isUpdate) => {
    const formData = isUpdate ? updateForm : addForm;
    const setError = isUpdate ? setErrorUpdateStaff : setErrorAddStaff;
    const errors = validateForm(formData);
    setError(errors);
    if (Object.keys(errors).length > 0) {
      return 
    }
    if (isUpdate) {
        const { email } = updateForm;
        dispatch(updateStaff({ id: updateStaffIdAndEmail, email: email })).then((res) => {
          if (res?.payload?.success) {
            toast.success("Staff updated successfully.");
            setOpenUpdateStaff(false);
            setUpdateForm({ email: "" });
            fetchData();
          } else {
            toast.error(SOMETHING_WRONG);
          }
        });
    } else {
        const { userName, email } = addForm;
        dispatch(
          createStaff({
            username: userName?.trim(),
            email: email?.trim(),
          })
        ).then((res) => {
          if (res?.payload?.success) {
            toast.success("Staff created successfully and credentials sent to their email.");
            setOpenAddStaff(false);
            setAddForm({ userName: "", email: "" });
            fetchData();
          } else if(res?.payload?.error === "You already have an account with us. Please login.") {
            toast.error("Staff is already exist");
          } else if(res?.payload?.error?.includes("duplicate key value")) {
            toast.error("Staff is already exist");
          }else{
            toast.error(SOMETHING_WRONG);
          }
        });
    }
  };

  const handleKeyDown = (e, isUpdate) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if(isUpdate){
        handleSubmit(true);
      }else{
        handleSubmit(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, debounceSearch]);

  const handlePageChange = useCallback((event, value) => {
    setCurrentPage(value); // Update the current page
  }, []);

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">Staff List</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search by name or email"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value?.trimStart())}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
              <SoftBox onClick={() => setOpenAddStaff(true)} style={{ cursor: "pointer" }}>
                <SoftButton
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="outlined"
                  color="primary"
                >
                  Add Staff
                  <Icon fontWeight="medium" style={{ marginLeft: "5px" }}>
                    <Add />
                  </Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                columns={STAFF_LIST_COLUMNS}
                rows={currentRows}
                text={"staff"}
                loader={staffLoader}
              />
            </SoftBox>
          </Card>
        </SoftBox>
        {totalStaff > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalStaff / pageSize)} // Total pages calculated from totalStaffs
                page={currentPage} // Current page
                onChange={handlePageChange} // Handle page change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      <StaffModal
        open={openAddStaff}
        title="Add Staff"
        formData={addForm}
        errors={errorAddStaff}
        onClose={() => handleModalClose("addStaff")}
        onChange={handleChange}
        onSubmit={() => handleSubmit(false)}
        handleKeyDown={handleKeyDown}
        userNameRef={userNameRef}
        emailRef={emailRef}
      />

      {openUpdateStaff && (<StaffModal
        open={openUpdateStaff}
        title="Update Staff"
        formData={updateForm}
        errors={errorUpdateStaff}
        onClose={() => handleModalClose("updateStaff")}
        onChange={(e) => handleChange(e, true)}
        onSubmit={() => handleSubmit(true)}
        handleKeyDown={handleKeyDown}
        emailRef={emailRef}
        isUpdate={true}
      />)}

      {openDeactiveModel && (
        <ConfirmationModel
          open={openDeactiveModel}
          title="Are you sure?"
          description={`You want to ${
            staffIdStatus?.status ? "Deactivate" : "Activate"
          } the staff?`}
          buttonContent={staffIdStatus?.status ? "Deactivate" : "Activate"}
          closeHandler={() => handleModalClose("deactive")}
          confirmHandler={() => handleConfirmAction("deactivate")}
        />
      )}

      {openDeleteModel && (
        <ConfirmationModel
          open={openDeleteModel}
          title="Are you sure?"
          description={`You want to delete the staff?`}
          buttonContent={"Delete"}
          closeHandler={() => handleModalClose("delete")}
          confirmHandler={() => handleConfirmAction("delete")}
        />
      )}
    </DashboardLayout>
  );
}

export default Staff;
