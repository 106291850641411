import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getSession } from "helper/authHelper";
import SoftTypography from "components/SoftTypography";
import { Card, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { ContactMail, Group, GroupAdd, KeyboardArrowRight, LocalOffer, People, School } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { GetDashboardCount } from "../../redux/ApiSlice/DashBoardSlice";
import { useEffect } from "react";
import { roles } from "../../utils/commonStrings";

function Dashboard() {
  document.title = "Prodigy-chap | Dashboard";
  const userInfo = getSession();
  const dispatch = useDispatch();
  const { data_counts = {} } = useSelector((state) => state.dashboard);
  const navigate = useNavigate();

  const dashboardItems = [
    {
      key: "total_staff",
      label: "Staff",
      route: "/staff",
      icon: <GroupAdd />,
    },
    {
      key: "total_student",
      label: "User",
      route: "/user-list",
      icon: <School />,
    },
    {
      key: "total_f1_package",
      label: "F1 Package",
      route: "/f1-packages",
      icon: <LocalOffer />,
    },
    {
      key: "total_b1_b2_package",
      label: "B1-B2 Package",
      route: "/b1-b2-packages",
      icon: <People />,
    },
    {
      key: "total_contact",
      label: "Contact Us",
      route: "/contact-us",
      icon: <ContactMail />,
    },
  ];

  const filteredDashboardItems = dashboardItems.filter((item) => {
    if (userInfo?.role === roles?.STAFF && item.key === "total_staff") {
      return false;
    }
    return true;
  });

  const fetchData = async () => {
    try {
      await dispatch(GetDashboardCount());
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <div className="dashboard-grid">
          {filteredDashboardItems.map(({ key, label, route, icon }) => (
            <Card key={key}>
              <SoftBox
                bgColor={"white"}
                variant="gradient"
                borderRadius="md"
                sx={{
                  position: "relative",
                  ...((userInfo?.role === roles?.ADMIN || roles?.STAFF) && {
                    "&:hover .hover-arrow": {
                      opacity: 1,
                      transform: "translateX(12px)", // Slide effect for arrow
                    },
                    "&:hover .group-icon-container": {
                      transform: "translateX(-12px)", // Shift Group icon left
                    },
                  }),
                }}
                onClick={() => navigate(route)}
              >
                <SoftBox p={2} style={{ cursor: "pointer" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <SoftBox ml={0} lineHeight={1}>
                        <SoftTypography
                          variant="button"
                          color={"dark"}
                          opacity={0.7}
                          textTransform="capitalize"
                        >
                          {label}
                        </SoftTypography>
                        <SoftTypography variant="h5" fontWeight="bold" color={"dark"}>
                          {data_counts[key] || 0}
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={4}>
                      <SoftBox
                        className={userInfo !== roles?.STAFF ? "group-icon-container" : ""}
                        variant="gradient"
                        bgColor={"secondary"}
                        color={"white"}
                        width="3rem"
                        height="3rem"
                        marginLeft="auto"
                        borderRadius="md"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        shadow="md"
                        sx={{
                          transition: "transform 0.3s ease", // Smooth transition for icon movement
                        }}
                      >
                        <Icon fontSize="small" color="inherit">
                          {icon}
                        </Icon>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <Icon
                  className="hover-arrow"
                  style={{ right: "16px", cursor: "pointer" }}
                  sx={{
                    position: "absolute",
                    top: "40%",
                    right: "16px",
                    transform: "translateY(-40%) translateX(-5px)",
                    opacity: 0,
                    transition: "opacity 0.1s ease, transform 0.3s ease",
                  }}
                  fontSize="24px"
                >
                  <KeyboardArrowRight style={{ cursor: "pointer" }} />
                </Icon>
              </SoftBox>
            </Card>
          ))}
        </div>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;
