import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, post, put } from "api/base"; // Adjust the import for API methods
import { getSession } from "helper/authHelper";

// Initial state for F1 packages
const initialState = {
  data_counts: {}
};

// Thunks for API calls
export const GetDashboardCount = createAsyncThunk("/admin/get-data-total-count", async () => {
  try {
    const response = await get("/admin/dashboard-counts");
    if (response?.data) {
      return response.data; 
    } else {
      throw new Error("Data not found in response");
    }
  } catch (e) {
    return rejectWithValue(e.response?.data || e.message);
  }
});

export const DashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetDashboardCount.pending, (state) => {
        state.DashBoardLoader = true;
      })
      .addCase(GetDashboardCount.fulfilled, (state, action) => {
        state.data_counts = action?.payload?.data;
        state.DashBoardLoader = false;
      })
      .addCase(GetDashboardCount.rejected, (state, action) => {
        state.DashBoardLoader = false;
      });
  },
});

// Export the reducer for use in the store
export default DashBoardSlice.reducer;
