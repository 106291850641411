import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del } from "api/base";
import { get } from "api/base";
const initialState = {
  ContactLoader: false,
  ContactUS: [],
  totalContact: 0,
};

export const GetContactUS = createAsyncThunk(
  "admin/get-contact-us",
  async ({ page = 0, limit = 0,search  }) => {
    try {
      const url =`admin/get-contact-us?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}`;
      const response = await get(url);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const deleteUser = createAsyncThunk("/admin/delete-contact", async (body) => {
  try {
    const response = await  del(`/admin/delete-contact/${body?.deleteContactId}`);
    return response;
  } catch (e) {
    return e.response;
  }
});

export const ContactUS = createSlice({
  name: "ContactUS",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetContactUS.pending, (state) => {
        state.ContactLoader = true;
      })
      .addCase(GetContactUS.fulfilled, (state, action) => {
        state.ContactLoader = false;
        
          state.ContactUS = action.payload?.data?.contacts || [];
          state.totalContact = action?.payload?.data?.total_contacts || 0;
        
      })
      .addCase(GetContactUS.rejected, (state, action) => {
        state.ContactLoader = false;
      });
  },
});

export default ContactUS.reducer;
