import moment from "moment";
import { useEffect, useState } from "react";

//------------------------------------regex------------------------------------------------//
export const EMAIL_REGEX =
  /^(?!.*[@]{2})(?!.*[._%+-]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d)(?=.*[$%&*@!#])[A-Za-z\d$%&*@!#]{8,}$/;
//-------------------------------------Array list-------------------------------------//
export const STAFF_LIST_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "name", align: "left" },
  { name: "email", align: "left" },
  { name: "status", align: "left" },
  { name: "action", align: "center" },
];
export const USER_LIST_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "Full name", align: "center" },
  { name: "email", align: "left" },
  { name: "status", align: "left" },
  { name: "action", align: "center" },
];
export const B1_B2_PACKAGES_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "date", align: "left" },
  { name: "name", align: "left" },
  { name: "email", align: "left" },
  { name: "employment", align: "left" },
  { name: "income", align: "left" },
  { name: "sponsorship", align: "left" },
  { name: "country", align: "left" },
  { name: "phone", align: "left" },
  { name: "chat app", align: "left" },
  { name: "chat id", align: "left" },
  { name: "action", align: "center" },
];
export const F1_PACKAGES_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "date", align: "left" },
  { name: "name", align: "left" },
  { name: "email", align: "left" },
  { name: "current education", align: "left" },
  { name: "intended major", align: "left" },
  { name: "sponsorship", align: "left" },
  { name: "country", align: "left" },
  { name: "phone", align: "left" },
  { name: "chat app", align: "left" },
  { name: "chat id", align: "left" },
  { name: "action", align: "center" },
];
export const F1_VISA_TRAINING_MOCK_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "date", align: "left" },
  { name: "name", align: "left" },
  { name: "email", align: "left" },
  { name: "current education", align: "left" },
  { name: "intended major", align: "left" },
  { name: "college-university", align: "left" },
  { name: "sponsorship", align: "left" },
  { name: "training plan", align: "left" },
  { name: "plan price", align: "left" },
  { name: "country", align: "left" },
  { name: "phone", align: "left" },
  { name: "chat app", align: "left" },
  { name: "chat id", align: "left" },
  { name: "verified by", align: "left" },
  { name: "payment status", align: "center" },
  { name: "action", align: "center" },
];
export const CONTACT_US_LIST_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "date", align: "left" },
  { name: "Full name", align: "center" },
  { name: "Email", align: "left" },
  { name: "Visa type", align: "center" },
  { name: "Country", align: "left" },
  { name: "Phone", align: "left" },
  { name: "Chat app", align: "left" },
  { name: "Chat App id", align: "left" },
  { name: "message", align: "center" },
  { name: "action", align: "center" },
];
export const TRAINING_LOGS_COLUMNS = [
  { name: "sr", align: "center" },
  { name: "family member name", align: "left" },
  { name: "email", align: "left" },
  { name: "created date", align: "left" },
  { name: "action", align: "center" },
];
export const CALL_DURATION = [
  { label: "5 minutes", value: 5 },
  { label: "10 minutes", value: 10 },
  { label: "15 minutes", value: 15 },
  { label: "20 minutes", value: 20 },
];

export const CURRENT_EDUCATION_LEVEL = [
  { label: "Middle School(Grade 5 onwards)", value: "Middle School(Grade 5 onwards)" },
  { label: "Still in High School(Grade 10 onwards)", value: "Still in High School(Grade 10 onwards)" },
  { label: "High School Graduate(GED, GCE O, Grade 12, IB, OSSD etc.)", value: "High School Graduate(GED, GCE O, Grade 12, IB, OSSD etc.)" },
  { label: "Still Studying Bachelor", value: "Still Studying Bachelor" },
  { label: "Bachelor Graduate", value: "Bachelor Graduate" },
  { label: "Still studying Master", value: "Still studying Master" },
  { label: "Master Graduate", value: "Master Graduate" },
];

export const SPONSERSHIP = [
  { label: "Parents", value: "Parents" },
  { label: "Other Family members (spouse, sister/brother)", value: "Other Family members (spouse, sister/brother)" },
  { label: "Other relatives (aunt, uncle)", value: "Other relatives (aunt, uncle)" },
  { label: "Employer", value: "Employer" },
  { label: "Other organization/company", value: "Other organization/company" },
];

export const CHAT_APP = [
  { label: "Viber", value: "Viber" },
  { label: "LINE", value: "LINE" },
  { label: "Whatsapp", value: "Whatsapp" },
];

export const EMPLOYMENT = [
  { label: "Employee of a company", value: "Employee of a company" },
  { label: "Have own business", value: "Have own business" },
];

export const INCOME_LEVEL = [
  { label: "Less than 5000 USD", value: "Less than 5000 USD" },
  { label: "Between 5001 - 10,000 USD", value: "Between 5001 - 10,000 USD" },
  { label: "Over 10,000 USD", value: "Over 10,000 USD" },
];

const PLAN_OPTIONS = [
  { value: "F1 Visa", label: "F1 Visa" },
  { value: "B1/B2 Visa", label: "B1/B2 Visa" },
  { value: "F1 Package", label: "F1 Package" },
  { value: "B1/B2 Package", label: "B1/B2 Package" },
]

export const PLAN_FIELDS = [
  { label: 'Plan Name', name: 'plan_name' },
  { label: 'USD Price', name: 'usd_price' },
  { label: 'Kyat Price', name: 'kyat_price' },
  { label: 'Baht Price', name: 'baht_price' },
  { label: 'Description', name: 'plan_description' },
  { label: 'Category', name: 'category', isDropDown: true, options: PLAN_OPTIONS },
];

export const mainRelations = [
  "Family",
  "Guardian",
  "Social Worker",
  "Healthcare Provider",
  "Educational",
  "Friend",
  "Professional",
  "Other",
];

export const PatientTypes = [
  {
    value: "NewPatient",
    label: "New patient",
  },
  {
    value: "ExistingResidentPatient",
    label: "Existing resident patient",
  },
  {
    value: "LocalCouncilNewPatient",
    label: "Local council new patient",
  },
  {
    value: "LocalCouncilReferredPatientWithoutFamily",
    label: "Local council referred patient without family",
  },
];

export const relations = [
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Siblings", label: "Siblings" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
  { value: "Spouse", label: "Spouse" },
  { value: "Other", label: "Other" },
];

//------------------------------------default value--------------------------------------//
export const GENERAL_INFO_PATIENT = {
  lastName: "",
  firstName: "",
  birthdate: "",
  patientEmail: "",
  patientType: "NewPatient",
  gender: "Male",
};

export const FAMILY_MEMBER_INFO = {
  relation: "",
  otherRelation: "",
  name: "",
  email: "",
  gender: "Male",
  id: null,
  birth_date: "",
};

export const FAMILY_MODAL = {
  isOpen: false,
  isEdit: false,
  memberInfo: {},
  memberIndex: null,
};

export const FILE_TYPE = ["image", "video", "audio"];

//----------------------------validation function------------------------------------//
export const validatePassword = (password) => {
  const errors = [];
  if (password.length < 8) errors.push(PASSWORD_VALIDATION_MESSAGES.minLength);
  if (!/[A-Z]/.test(password)) errors.push(PASSWORD_VALIDATION_MESSAGES.uppercase);
  if (!/[a-z]/.test(password)) errors.push(PASSWORD_VALIDATION_MESSAGES.lowercase);
  if (!/[0-9]/.test(password)) errors.push(PASSWORD_VALIDATION_MESSAGES.digit);
  if (!/[^A-Za-z0-9]/.test(password)) errors.push(PASSWORD_VALIDATION_MESSAGES.specialChar);

  if (errors.length > 0) {
    return PASSWORD_VALIDATION_MESSAGES.summary;
  }

  return "";
};
export function isEmpty(value) {
  return (
    value == null ||
    value == undefined ||
    value == 0 ||
    (typeof value === "string" && !value?.trim()) ||
    (Array?.isArray(value) && !value?.length)
  );
}
export const capitalizeValue = (value) => {
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
};
export const functionGetTime = (time) => {
  return moment?.utc(time)?.local()?.format("DD MMM YYYY - hh:mm:ss A");
};

export const getDateLabel = (dateString) => {
  const today = moment()?.startOf("day"); // Set today to the start of the day for accurate comparisons
  const messageDate = moment(dateString?.substring(0, 19)); // Remove the microseconds part of the string
  const diffInDays = today.diff(messageDate?.startOf("day"), "days"); // Compare start of days for accurate day difference

  if (diffInDays === 0) return "Today";
  if (diffInDays === 1) return "Yesterday";
  if (diffInDays < 7) return messageDate.format("dddd");
  return messageDate?.format("MMMM D, YYYY");
};

//----------------------------validation message --------------------------------------//
export const PASSWORD_VALIDATION_MESSAGES = {
  minLength: "at least 8 characters",
  uppercase: "one uppercase letter",
  lowercase: "one lowercase letter",
  digit: "one number",
  specialChar: "one special character",
  summary:
    "Please enter at least 8 characters, including an uppercase letter, lowercase letter, number, and special character.",
};

//----------------------------message --------------------------------------//
export const SOMETHING_WRONG = "Oops! Something went wrong";
export const SUCCESSFULLY_LOGIN = "You have successfully logged in.";
export const DELETE_PATIENT = "Patient has been deleted successfully";
export const UPDATE_PATIENT = "Patient and family members updated successfully";
export const CREATE_PATIENT = "Patient and family members created successfully";
export const ONE_FAMILY_REQUIRED = "At least one family member must be included.";
export const SESSION_EXPIRED = "Session expired. Sign in again.";

export const apiKey = "9OgS1MSc6ydaxs7vHSXh7QrK6tH0JpCj1KDXAGQplMpQVrGz";
export const configId = "1079d22e-679c-4880-aac1-a43349c1a70a";
export const secretKey = "eUyU45kjzd03936AZUPc853brBQR3Qqw7AGXuVfe1HtR3YN1xF2QjN77cudc8xrA";

export const VIEW_OPTIONS = [
  { id: "month", label: "Month" },
  { id: "agenda", label: "Agenda" },
];
