import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import SoftBadge from "components/SoftBadge";
import active from "../../assets/images/active.png";
import deactive from "../../assets/images/deactive.png";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Pagination, Tooltip, Typography } from "@mui/material";
import "./patient.css";
import { Clear } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { GetUserList ,activeUser, deactiveUser } from "../../redux/ApiSlice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { USER_LIST_COLUMNS } from "helper/constant";
import useDebounce from "helper/useDebounce";
import { formatTypography } from "../../utils/index";

function UserListComponent() {
  document.title = "Prodigy-chap | User";
  const { studentsInfo, totalStudents, studentsLoader } = useSelector((state) => state.user);
  const [openDeactiveModel, setOpenDeactiveModel] = useState(false);
  const [userIdStatus, setUserIdStatus] = useState({ id: "", status: "" });
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const currentRows = studentsInfo?.map((item, index) => ({
      "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
      "Full name": formatTypography(item?.fullname),
      "email": formatTypography(item?.email),
      "status": (
        <SoftBadge
          variant="gradient"
          badgeContent={item.is_active === true ? "Active" : "Deactive"}
          color={item.is_active === true ? "primary" : "secondary"}
          size="xs"
          container
        />
      ),
      "action": (
        <Box sx={{ gap: "10px", display: "flex" }}>
          <Tooltip title={item.is_active === true ? "Deactive" : "Active"} placement="top">
            <Icon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleDeactiveConfirm(item?.id, item?.is_active);
              }}
              fontSize="1px"
              style={{ fontSize: "18px" }}
            >
              {item.is_active === true ? (
                <img src={deactive} alt="Deactive icon" />
              ) : (
                <img src={active} alt="active icon" />
              )}
            </Icon>
          </Tooltip>
        </Box>
      ),
    }));

  const fetchData = async () => {
    try {
      await dispatch(
        GetUserList({
          search:debounceSearch,
          page: currentPage, // Send current page
          limit: pageSize,
        })
      );
    } catch (error) {}
  };

  const handleClose = (type) => {
    if (type === "deactive") {
      setOpenDeactiveModel(false);
    }
  };

  const handleDeactiveConfirm = (id, status) => {
    setOpenDeactiveModel(true);
    setUserIdStatus({ id, status });
  };

  const handleConfirmDeactive = async () => {
    if (userIdStatus?.id && userIdStatus?.status) {
      const response = await dispatch(
        deactiveUser(userIdStatus.id)
      );
      setOpenDeactiveModel(false);
      if (response?.payload?.data?.success) {
        toast.success(`User deactivated successfully`);
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }else if(userIdStatus?.id && !userIdStatus?.status){
      const response = await dispatch(
        activeUser(userIdStatus.id)
      );
      setOpenDeactiveModel(false);
      if (response?.payload?.data?.success) {
        toast.success(`User activated successfully`);
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage,debounceSearch]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">User List</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search by name or email"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value?.trimStart());
                      setCurrentPage(1);
                    }}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white"
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                columns={USER_LIST_COLUMNS}
                rows={currentRows}
                text={"user"}
                loader={studentsLoader}
              />
            </SoftBox>
          </Card>
        </SoftBox>
        {totalStudents > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalStudents / pageSize)} // Total pages calculated from totalStudentss
                page={currentPage} // Current page
                onChange={handlePageChange} // Handle page change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      {openDeactiveModel && (
        <ConfirmationModel
          open={openDeactiveModel}
          title="Are you sure?"
          description={`You want to ${
            userIdStatus?.status ? "Deactivate" : "Activate"
          } the staff?`}
          buttonContent={userIdStatus?.status ? "Deactivate" : "Activate"}
          closeHandler={() => handleClose("deactive")}
          confirmHandler={handleConfirmDeactive}
        />
      )}
      
    </DashboardLayout>
  );
}

export default UserListComponent;
