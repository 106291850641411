import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Modal, Typography, Icon } from "@mui/material";
import { Close } from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./packageModal.css";
import customSelectStyles from "./customSelectStyles";

const PackageUpadtaionModal = React.memo(({
    open,
    title,
    formData,
    fields,
    errors,
    onClose,
    onChange,
    onSubmit
}) => {
    const options = useMemo(() => countryList().getData(), []);
    const [value, setValue] = useState(options.find(option => option.label === formData?.country));

    const handleCountryChange = (selectedOption) => {
        setValue(selectedOption);
        onChange({ target: { name: 'country', value: selectedOption?.label } });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="dynamic-form-modal-title"
            aria-describedby="dynamic-form-modal-description"
            sx={{ outline: "none" }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 850,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                    outline: "none",
                }}
            >
                <Icon
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        color: "text.secondary",
                    }}
                >
                    <Close />
                </Icon>

                <Typography id="dynamic-form-modal-title" variant="h4" component="h2" gutterBottom>
                    {title}
                </Typography>

                <SoftBox component="form" role="form" sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px" }}>
                    {fields.map((field) => (
                        <SoftBox key={field.name} sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            justifyContent: "space-between",
                        }} >
                            <SoftBox style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}>
                                <label style={{ display: "block", textAlign: "left" }}>
                                    {field.label} {field.required && <span>*</span>}
                                </label>

                                {/* Render input based on type */}
                                {field.type === "text" && (
                                    <SoftInput
                                        placeholder={field.placeholder}
                                        name={field.name}
                                        value={formData[field.name] || ""}
                                        onChange={(e) => onChange(e, field.name)}
                                        error={!!errors[`${field.name}Err`]}
                                        sx={{ width: "100%", padding: "0.70rem 0.75rem" }}
                                    />
                                )}

                                {field.type === "countryDropdown" && (
                                    <Select
                                        name="country"
                                        options={options}
                                        value={value}
                                        onChange={handleCountryChange}
                                        placeholder="Select country"
                                        isClearable
                                        styles={customSelectStyles}
                                    />
                                )}

                                {field.type === "dropdown" && (
                                    <Select
                                        name={field?.name}
                                        options={field?.options}
                                        value={field?.options?.find(option => option?.value === formData[field?.name])}
                                        onChange={(selectedOption) =>
                                            onChange({
                                                target: {
                                                    name: field?.name,
                                                    value: selectedOption?.value,
                                                },
                                            })
                                        }
                                        placeholder={`Select ${field?.label}`}
                                        isClearable
                                        styles={customSelectStyles}
                                    />
                                )}

                                {field.type === "phone" && (
                                    <PhoneInput
                                        country={"us"}
                                        value={formData[field.name] || ""}
                                        onChange={(value) => onChange({ target: { name: field.name, value } })}
                                        inputProps={{
                                            name: field.name,
                                            required: field.required,
                                        }}
                                        textAlign="start"
                                        style={{ width: "100%" }}
                                    />
                                )}

                                {/* Render error message */}
                                <span className="error">{errors[`${field.name}Err`]}</span>
                            </SoftBox>
                        </SoftBox>
                    ))}
                </SoftBox>

                <SoftBox style={{ marginTop: "20px" }}>
                    <SoftButton variant="gradient" color="primary" onClick={() => onClose()} sx={{ mr: 1 }}>
                        Cancel
                    </SoftButton>
                    <SoftButton variant="gradient" color="secondary" onClick={() => onSubmit()}>
                        Submit
                    </SoftButton>
                </SoftBox>
            </Box>
        </Modal>
    );
});

PackageUpadtaionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.oneOf(["text", "dropdown", "phone"]).isRequired,
            placeholder: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                    label: PropTypes.string.isRequired,
                })
            ),
            required: PropTypes.bool,
            isCountryDropdown: PropTypes.bool,
        })
    ).isRequired,
    errors: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default PackageUpadtaionModal;
