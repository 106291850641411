import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { put } from "api/base";
import { get, post } from "api/base";
const initialState = {
  studentsLoader: false,
  studentsInfo: [],
  totalStudents: 0,
};

export const GetUserList = createAsyncThunk(
  "admin/get-all-students",
  async ({ page = 0, limit = 0,search }) => {
    try {
      const url =  `admin/get-all-students?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}`
      const response = await get(url);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const createStaff = createAsyncThunk("admin/create-staff", async (body) => {
  try {
    const response = await post(`admin/create-staff`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});



export const deactiveStaff = createAsyncThunk("/admin/staff-status", async (body) => {
  try {
    const response = await post("/admin/staff-status", body);
    return response;
  } catch (e) {
    return e.response;
  }
});

export const deactiveUser = createAsyncThunk("/admin/deactivate-user/", async (body) => {
  try {
    const response = await put(`/admin/deactivate-user/${body}`);
    return response;
  } catch (e) {
    return e.response;
  }
});

export const activeUser = createAsyncThunk("/admin/reactivate-user", async (body) => {
  try {
    const response = await put(`/admin/reactivate-user/${body}`);
    return response;
  } catch (e) {
    return e.response;
  }
});

export const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetUserList.pending, (state) => {
        state.studentsLoader = true;
      })
      .addCase(GetUserList.fulfilled, (state, action) => {
        state.studentsLoader = false;
        
          state.studentsInfo = action?.payload?.data?.students || [];
          state.totalStudents = action?.payload?.data?.total_students || 0;
        
      })
      .addCase(GetUserList.rejected, (state, action) => {
        state.studentsLoader = false;
      })
      .addCase(activeUser.pending, (state) => {
        state.studentsLoader = true;
      })
      .addCase(activeUser.fulfilled, (state, action) => {
        state.studentsLoader = false;
        
          state.studentsInfo = action?.payload?.data?.students || [];
          state.totalStudents = action?.payload?.data?.total_students || 0;
        
      })
      .addCase(activeUser.rejected, (state, action) => {
        state.studentsLoader = false;
      })
      .addCase(deactiveUser.pending, (state) => {
        state.studentsLoader = true;
      })
      .addCase(deactiveUser.fulfilled, (state, action) => {
        state.studentsLoader = false;
        
          state.studentsInfo = action?.payload?.data?.students || [];
          state.totalStudents = action?.payload?.data?.total_students || 0;
        
      })
      .addCase(deactiveUser.rejected, (state, action) => {
        state.studentsLoader = false;
      });
  },
});

export default UserSlice.reducer;
