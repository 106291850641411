import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { put } from "api/base";
import { del } from "api/base";
import { get } from "api/base";
import { post } from "api/base";
import { authHeader } from "helper/authHelper";
const initialState = {
  staffLoader: false,
  chatLoader: false,
  staffInfo: [],
};

export const GetAllStaffs = createAsyncThunk(
  "admin/get-all-staffs",
  async ({ page = 0, limit = 0, search }) => {
    try {
      const url = `admin/get-all-staffs?skip=${page}&limit=${limit}${ search ? `&search=${search}` : `` }`;
      const response = await get(url);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const createStaff = createAsyncThunk("admin/create-staff", async (body) => {
  try {
    const response = await post(`admin/create-staff`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const updateStaff = createAsyncThunk("/admin/update-staff", async (body) => {
  try {
    const response = await put(`/admin/update-staff`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const createFamilyMember = createAsyncThunk(
  "/patients/add-family-member",
  async ({ id, body }) => {
    try {
      const response = await post(`/patients/add-family-member/${id}`, body);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const updatePatient = createAsyncThunk("/patients/edit-patient", async ({ id, body }) => {
  try {
    const response = await put(`/patients/edit-patient/${id}`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
export const deletePatient = createAsyncThunk("/patients/delete-patient", async ({ id }) => {
  try {
    const response = await del(`/patients/delete-patient/${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deactiveStaff = createAsyncThunk("/admin/staff-status", async (body) => {
  try {
    const response = await post("/admin/staff-status", body);
    return response;
  } catch (e) {
    return e.response;
  }
});

export const deleteStaff = createAsyncThunk("/admin/delete-staff", async (body) => {
  try {
    const response = await del(`/admin/delete-staff/${body}` );
    return response;
  } catch (e) {
    return e.response;
  }
});

export const deleteMember = createAsyncThunk(
  "/patients/delete-family-member",
  async ({ memberId }) => {
    try {
      const response = await del(`/patients/delete-family-member/${memberId}`);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getPatientById = createAsyncThunk("/patients/get-patient", async ({ id }) => {
  try {
    const response = await get(`/patients/get-patient/${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const uploadPatientInfo = createAsyncThunk("/bots/train-bot", async ({ body }) => {
  try {
    const response = await post(`/bots/train-bot`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
export const createCallHistory = createAsyncThunk("/call/create-call-history", async (body) => {
  try {
    const response = await post(`/call/create-call-history`, body);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
export const getAllChat = createAsyncThunk("/chat", async (id) => {
  try {
    const response = await get(`/bots/chat-history/${id}`);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});
export const patientSlice = createSlice({
  name: "patient",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllStaffs.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(GetAllStaffs.fulfilled, (state, action) => {
        state.staffLoader = false;
        // if (action?.payload?.success) {
        state.staffInfo = action.payload?.data?.staffs || [];
        state.totalStaff = action.payload?.data?.total_staff || [];
        // }
      })
      .addCase(GetAllStaffs.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(createStaff.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(createStaff.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(createStaff.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(createFamilyMember.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(createFamilyMember.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(createFamilyMember.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(updateStaff.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.staffLoader = false;
      })

      .addCase(deleteStaff.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(deleteMember.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(deleteMember.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(getPatientById.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(getPatientById.rejected, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(uploadPatientInfo.pending, (state) => {
        state.staffLoader = true;
      })
      .addCase(uploadPatientInfo.fulfilled, (state, action) => {
        state.staffLoader = false;
      })
      .addCase(uploadPatientInfo.rejected, (state, action) => {
        state.staffLoader = false;
      })

      .addCase(getAllChat.pending, (state) => {
        state.chatLoader = true;
      })
      .addCase(getAllChat.fulfilled, (state, action) => {
        state.chatLoader = false;
      })
      .addCase(getAllChat.rejected, (state, action) => {
        state.chatLoader = false;
      });
  },
});

export default patientSlice.reducer;
