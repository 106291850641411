import React from "react";
import PropTypes from "prop-types";
import { Box, Modal, Typography, Icon } from "@mui/material";
import { Close } from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

const StaffModal = ({
  open,
  title,
  formData,
  errors,
  onClose,
  onChange,
  onSubmit,
  handleKeyDown,
  userNameRef,
  emailRef,
  isUpdate = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="staff-modal-title"
      aria-describedby="staff-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          outline: "none",
        }}
      >
        <Icon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "text.secondary",
          }}
        >
          <Close />
        </Icon>

        <Typography id="staff-modal-title" variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>

        <SoftBox component="form" role="form" onKeyDown={(e) => handleKeyDown(e, isUpdate)}>
          {!isUpdate && (
            <SoftBox>
              <label style={{ display: "block", textAlign: "left" }}>
                Name <span>*</span>
              </label>
              <SoftInput
                placeholder="Enter username"
                name="userName"
                value={formData.userName}
                onChange={(e) => {
                  const input = e.target.value;
                  const capitalized = input.charAt(0).toUpperCase() + input.slice(1);
                  onChange({ target: { name: "userName", value: capitalized } }, isUpdate);
                }}
                error={!!errors.userNameErr}
                inputRef={userNameRef}
              />
              <span className="error">{errors.userNameErr}</span>
            </SoftBox>
          )}

          <SoftBox>
            <label style={{ display: "block", textAlign: "left", marginTop: "10px" }}>
              Email <span>*</span>
            </label>
            <SoftInput
              placeholder="Enter email"
              name="email"
              value={formData.email}
              onChange={(e) => onChange(e, isUpdate)}
              error={!!errors.emailErr}
              inputRef={emailRef}
            />
            <span className="error">{errors.emailErr}</span>
          </SoftBox>
        </SoftBox>

        <SoftBox style={{ marginTop: "20px" }}>
          <SoftButton variant="gradient" color="primary" onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </SoftButton>
          <SoftButton variant="gradient" color="secondary" onClick={onSubmit}>
            {isUpdate ? "Update" : "Add"}
          </SoftButton>
        </SoftBox>
      </Box>
    </Modal>
  );
};

StaffModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    userName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    userNameErr: PropTypes.string,
    emailErr: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  userNameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  emailRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isUpdate: PropTypes.bool,
};

export default StaffModal;
