import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del } from "api/base";
import { get, post, put } from "api/base";  // Adjust the import for API methods
import { getSession } from "helper/authHelper";
import { roles } from "../../utils/commonStrings";

// Initial state for F1 packages
const initialState = {
  f1VisaMockLoader: false,
  f1VisaMockInfo: [],
  totalF1VisaMock: 0,
};

// Get the user session to determine role
const userInfo = getSession();

export const updateF1Package = createAsyncThunk("/admin/update-package/f1", async (body) => {
  try {
    const response = await put(`/admin/update-package/f1/${body?.id}`, body?.data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteF1Package = createAsyncThunk("/admin/delete-f1-package", async (body) => {
  const url = userInfo?.role === roles?.ADMIN || roles?.STAFF && `/admin/delete-f1-package/${body}`;
  try {
    const response = await del(url);
    return response;
  } catch (e) {
    return e.response;
  }
});

// Slice for F1 packages
export const F1VisaMockSlice = createSlice({
  name: "f1VisaMock",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateF1Package.pending, (state) => {
        state.f1VisaMockLoader = true;
      })
      .addCase(updateF1Package.fulfilled, (state, action) => {
        state.f1VisaMockLoader = false;
      })
      .addCase(updateF1Package.rejected, (state) => {
        state.f1VisaMockLoader = false;
      })
      .addCase(deleteF1Package.pending, (state) => {
        state.f1VisaMockLoader = true;
      })
      .addCase(deleteF1Package.fulfilled, (state, action) => {
        state.f1VisaMockLoader = false;
      })
      .addCase(deleteF1Package.rejected, (state) => {
        state.f1VisaMockLoader = false;
      });
  },
});

// Export the reducer for use in the store
export default F1VisaMockSlice.reducer;
