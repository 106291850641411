import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del } from "api/base";
import { get, post, put } from "api/base";  // Adjust the import for API methods
import { getSession } from "helper/authHelper";
import { roles } from "../../utils/commonStrings";

// Initial state for F1 packages
const initialState = {
  f1VisaTrainingMockLoader: false,
  f1VisaTrainingMockInfo: [],
  totalF1VisaTrainingMock: 0,
};

// Get the user session to determine role
const userInfo = getSession();

// Thunks for API calls
export const GetAllF1VisaTraining = createAsyncThunk("f1-visa/get-all-f1-visa", async ({ page = 0, limit = 0, plan, search }) => {
    const url = userInfo?.role === roles?.ADMIN || roles?.STAFF ? `/admin/get-all-f1-visa?plan_name=${plan}&skip=${page}&limit=${limit}${search?`&search=${search}`  :``}` : ""
    try {
      const response = await get(url);
      if (response?.data?.data) {
        return response.data.data; 
      } else {
        throw new Error("Data not found in response");
      }
    } catch (e) {
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);


export const verifyPayment = createAsyncThunk("/admin/f1-visa-verification", async (body) => {
  const url = userInfo?.role === roles?.ADMIN || roles?.STAFF ? `/admin/f1-visa-verification/${body}` : ""
  try {
    const response = await get(url);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const updateF1Package = createAsyncThunk("/admin/update-package/f1", async (body) => {
  try {
    const response = await put(`/admin/update-package/f1/${body?.id}`, body?.data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteF1Package = createAsyncThunk("/admin/delete-f1-package", async (body) => {
  const url = userInfo?.role === roles?.ADMIN || roles?.STAFF && `/admin/delete-f1-package/${body}`;
  try {
    const response = await del(url);
    return response;
  } catch (e) {
    return e.response;
  }
});

// Slice for F1 packages
export const F1VisaTrainingSlice = createSlice({
  name: "f1VisaTraining",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllF1VisaTraining.pending, (state) => {
        state.f1VisaTrainingMockLoader = true;
      })
      .addCase(GetAllF1VisaTraining.fulfilled, (state, action) => {
        state.f1VisaTrainingMockLoader = false;
        state.f1VisaTrainingMockInfo = action.payload?.f1_visas || []; 
        state.totalF1VisaTrainingMock = action.payload?.total_visa || 0; 
      })
      .addCase(GetAllF1VisaTraining.rejected, (state, action) => {
        state.f1VisaTrainingMockLoader = false;
      })
      .addCase(verifyPayment.pending, (state) => {
        state.f1VisaTrainingMockLoader = true;
      })
      .addCase(verifyPayment.fulfilled, (state) => {
        state.f1VisaTrainingMockLoader = false;
      })
      .addCase(verifyPayment.rejected, (state) => {
        state.f1VisaTrainingMockLoader = false;
      })
      .addCase(deleteF1Package.pending, (state) => {
        state.f1VisaTrainingMockLoader = true;
      })
      .addCase(deleteF1Package.fulfilled, (state, action) => {
        state.f1VisaTrainingMockLoader = false;
      })
      .addCase(deleteF1Package.rejected, (state) => {
        state.f1VisaTrainingMockLoader = false;
      });
  },
});

// Export the reducer for use in the store
export default F1VisaTrainingSlice.reducer;
