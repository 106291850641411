import React, { useCallback, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Pagination, Tooltip } from "@mui/material";
import "./patient.css";
import { Clear, Delete, Verified } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { F1_VISA_TRAINING_MOCK_COLUMNS } from "helper/constant";
import useDebounce from "helper/useDebounce";
import { deleteF1Package } from "../../redux/ApiSlice/F1PackageSlice";
import { formatTypography } from "../../utils/index";
import { GetAllF1VisaTraining, verifyPayment } from "../../redux/ApiSlice/F1VisaTrainingSlice";
import SoftBadge from "components/SoftBadge";

function F1VisaTraining() {
  document.title = "Prodigy-chap | F1 Visa Training";
  const { f1VisaTrainingMockInfo, totalF1VisaTrainingMock, f1VisaTrainingMockLoader } = useSelector(
    (state) => state.f1VisaTraining
  );
  const classDecide = f1VisaTrainingMockInfo?.some(item => item?.payment_slip && !item?.is_verified ? true : false);
  const [openVerifyPaymentModal, setOpenVerifyPaymentModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [paymentVerificationId, setPaymentVerificationId] = useState(null);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const currentRows = useMemo(() => f1VisaTrainingMockInfo?.map((item, index) => ({
      "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
      "date": formatTypography(new Date(item?.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'numeric',
        year: 'numeric',
      })),
      "name": formatTypography(item?.name),
      "email": formatTypography(item?.email),
      "current education": formatTypography(item?.current_education_level),
      "college-university": formatTypography(item?.us_collage),
      "intended major": formatTypography(item?.intended_major),
      "sponsorship": formatTypography(item?.sponsorship),
      "training plan": formatTypography(item?.plan_name),
      "plan price": formatTypography(`$${item?.plan_price?.usd_price}` || "-"),
      "country": formatTypography(item?.country),
      "phone": formatTypography(`+ ${item?.phone_number}`),
      "chat app": formatTypography(item?.chat_app),
      "chat id": formatTypography(item?.chat_app_id),
      "verified by": formatTypography(item?.is_verified_by || "-"),
      "payment status":
      <SoftBadge
          variant="gradient"
          badgeContent={!item?.payment_slip ? "Pending Payment" : (item?.payment_slip && !item?.is_verified) ? "Pending Verification" : "Payment Verified" }
          color={!item?.payment_slip ? "error" : (item?.payment_slip && !item?.is_verified) ? "secondary" : "primary"}
          size="xs"
          container
        />,
      "action": (<Box sx={{ gap: "10px", display: "flex" }}>{" "}
          {
            (item?.payment_slip && !item?.is_verified) ?
          <Tooltip title="Verify Payment" placement="top">
            <Icon
              sx={{ cursor: "pointer"}}
              onClick={() => {
                handlePaymentVerifiaction(item);
              }}
              fontSize="1px"
              style={{ fontSize: "18px" }}
            >
              <Verified />
            </Icon>
          </Tooltip> : "-"
          }
        </Box>
      ),
    })), [f1VisaTrainingMockInfo, currentPage, pageSize]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(
        GetAllF1VisaTraining({
          search: debounceSearch,
          page: currentPage,
          page: currentPage,
          limit: pageSize,
          plan: "Training File Only"
        })
      );
    } catch (error) {
      toast.error(error.measseg);
    }
  }, [debounceSearch, currentPage, pageSize]);

  const handlePaymentVerifiaction = (paymentData) => {
    const allowedKeys = [
      "payment_channel",
      "payment_status",
      "phone_number",
      "plan_name",
      "plan_price",
      "sender_account_name",
      "payment_slip",
    ];
    const filteredDetails = allowedKeys.map((key) => ({
      key,
      value: paymentData[key] || "N/A",
    }));
    setPaymentVerificationId(paymentData?.id);
    setPaymentDetails(filteredDetails);
    setOpenVerifyPaymentModal(true);
  }; 

  const handleClose = (type) => {
      setOpenVerifyPaymentModal(false)
  };

  const handleVerify = async () => {
    const response = await dispatch(verifyPayment(paymentVerificationId));
    if(response?.payload?.success){
      toast.success(response?.payload?.message);
      setOpenVerifyPaymentModal(false);
      fetchData();
    }else{
      toast.error(SOMETHING_WRONG);
      setOpenVerifyPaymentModal(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">F1 Visa Training List</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search name, email or phone"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value?.trimStart());
                      setCurrentPage(1);
                    }}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white"
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
            <div className={classDecide ? "table-size table-sticky-payment-conditional" : "table-size table-sticky-verify-conditional"}>
              <Table
                columns={F1_VISA_TRAINING_MOCK_COLUMNS}
                rows={currentRows}
                text={"f1 visa training"}
                loader={f1VisaTrainingMockLoader}
              />
            </div>
            </SoftBox>
          </Card>
        </SoftBox>
        {totalF1VisaTrainingMock > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalF1VisaTrainingMock / pageSize)} // Total pages calculated from totalF1Packagess
                page={currentPage} // Current page
                onChange={handlePageChange} // Handle page change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      {openVerifyPaymentModal &&
        <ConfirmationModel
          open={openVerifyPaymentModal}
          title="Verify Payment"
          description={paymentDetails}
          buttonContent={"Verify"}
          closeHandler={() => handleClose("payment")}
          confirmHandler={handleVerify}
        />
      }
      
    </DashboardLayout>
  );
}

export default F1VisaTraining;
