import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Icon } from '@mui/material';
import SoftInput from 'components/SoftInput';
import PropTypes from "prop-types";
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import { Delete } from '@mui/icons-material';
import Select from "react-select";
import customSelectStyles from './customSelectStyles';

const PlanModal = ({ open, onClose, title, fields, formData, errors, onChange, onSubmit }) => {
  const [planDesc, setPlanDesc] = useState(formData["plan_description"] || []);
  const [currentDescription, setCurrentDescription] = useState('');

  useEffect(() => {
    formData["plan_description"] = planDesc;
  }, [planDesc]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 850,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
          outline: "none",
        }}
      >
        <Typography variant="h6" component="h4" align="center" mb={2}>
          {title}
        </Typography>
        <SoftBox>
        </SoftBox>
        <SoftBox
          component="form"
          role="form"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px"
          }}
        >
          {fields.map((field, index) => (
            <SoftBox
              key={field.name}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              <SoftBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <label
                  style={{
                    display: "block",
                    textAlign: "left"
                  }}
                >
                  {field.label}<span>*</span>
                </label>

                <SoftBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  {field.name !== 'category' ? (
                    <SoftInput
                      key={index}
                      name={field.name}
                      value={field.name === 'plan_description' ? currentDescription : formData[field.name] || ''}
                      onChange={(e) => {
                        if (field.name === 'plan_description') {
                          setCurrentDescription(e.target.value);
                        } else {
                          onChange(e);
                        }
                      }}
                      fullWidth
                      margin="normal"
                    />
                  ) : (
                    field.isDropDown && (
                      <div style={{ width: "100%" }}>
                        <Select
                          name={field.name}
                          options={field.options}
                          placeholder="Select Type of package"
                          styles={customSelectStyles}
                          value={field.options.find(option => option.value === formData[field.name]) || null}
                          onChange={(selectedOption) => {
                            onChange({ target: { name: field.name, value: selectedOption?.value || '' } });
                          }}
                        />
                      </div>
                    )
                  )}
                  {field.name === 'plan_description' && (
                    <SoftButton
                      onClick={() => {
                        if (currentDescription.trim()) {
                          const updatedPlanDesc = [...planDesc, currentDescription];
                          setPlanDesc(updatedPlanDesc);
                          setCurrentDescription('');
                          onChange({ target: { name: "plan_description", value: updatedPlanDesc } });
                        }
                      }}
                      sx={{
                        minWidth: "40px",
                        border: "1px solid #d2d6da",
                        color: "#d2d6da",
                      }}
                    >
                      +
                    </SoftButton>
                  )}
                </SoftBox>
                {errors[`${field.name}Err`] && (
                  <Typography variant="caption" color="error" align="left">
                    {errors[`${field.name}Err`]}
                  </Typography>
                )}
              </SoftBox>
            </SoftBox>
          ))}
          <SoftBox>
            {planDesc.length > 0 && <h5 style={{ textAlign: "start" }}>Preview</h5>}
            <ul style={{ textAlign: "left", marginTop: "10px", paddingLeft: "20px", fontSize: "14px" }}>
              {planDesc.length > 0 &&
                planDesc.map((item, index) => (
                  <SoftBox key={index} display="flex" justifyContent="space-between">
                    <li>{item}</li>
                    <Icon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        const updatedPlanDesc = planDesc.filter((desc, i) => i !== index);
                        setPlanDesc(updatedPlanDesc);
                        onChange({ target: { name: 'plan_description', value: updatedPlanDesc } });
                      }}
                      fontSize="1px"
                      style={{ fontSize: "18px" }}
                    >
                      <Delete />
                    </Icon>
                  </SoftBox>
                ))}
            </ul>
          </SoftBox>
        </SoftBox>
        <SoftBox style={{ marginTop: "20px" }}>
          <SoftButton variant="gradient" color="primary" onClick={() => onClose()} sx={{ mr: 1 }}>
            Cancel
          </SoftButton>
          <SoftButton variant="gradient" color="secondary" onClick={() => onSubmit()}>
            Submit
          </SoftButton>
        </SoftBox>
      </Box>
    </Modal>
  );
};

PlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  formData: PropTypes.object.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default PlanModal;