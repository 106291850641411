import { Close } from "@mui/icons-material";
import { Box, Icon, Modal, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import React, { useState } from "react";

export const ConfirmationModel = ({
    open,
    title,
    description,
    buttonContent,
    closeHandler,
    confirmHandler,
    onlyMessage,
}) => {
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");

    const formatKey = (key) => {
        return key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleVerifyClick = () => {
        setIsSecondModalOpen(true);
    };

    const handleSecondModalClose = () => {
        setIsSecondModalOpen(false);
    };

    const handlePreviewClick = (url) => {
        setPreviewUrl(url);
        setIsPreviewModalOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewUrl("");
        setIsPreviewModalOpen(false);
    };

    return (
        <>
            {/* Render first modal only if second modal is not open */}
            {!isSecondModalOpen && (
                <Modal
                    open={open}
                    onClose={closeHandler}
                    aria-labelledby="confirm-modal-title"
                    aria-describedby="confirm-modal-description"
                    sx={{ outline: "none" }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: typeof description === "string" ? 500 : 850,
                            bgcolor: "background.paper",
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 4,
                            textAlign: typeof description === "string" ? "center" : "center",
                            outline: "none",
                        }}
                    >
                        <Icon
                            aria-label="close"
                            onClick={closeHandler}
                            sx={
                                !onlyMessage
                                    ? {
                                        position: "absolute",
                                        top: 35,
                                        right: 30,
                                        color: "text.secondary",
                                        cursor: "pointer",
                                    }
                                    : {
                                        position: "absolute",
                                        top: 40,
                                        right: 25,
                                        color: "text.secondary",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                    }
                            }
                        >
                            <Close />
                        </Icon>

                        <Typography
                            id="confirm-modal-title"
                            variant="h4"
                            component="h2"
                            textAlign={!onlyMessage ? "" : "start"}
                            mb={!onlyMessage && 3}
                        >
                            {title}
                        </Typography>
                        {typeof description === "string" ? (
                            <Typography
                                id="confirm-modal-description"
                                variant="body2"
                                color="text.secondary"
                                mb={3}
                                mt={!onlyMessage ? "" : 3}
                                textAlign={!onlyMessage ? "" : "start"}
                            >
                                {description}
                            </Typography>
                        ) : (
                            Array.isArray(description) && (
                                <SoftBox
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(2, 1fr)",
                                    }}
                                >
                                    {description.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.key !== "payment_slip" ? (
                                                <Box
                                                    sx={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr 2fr",
                                                        gap: 2,
                                                        mb: 2,
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    <Typography
                                                        id={`confirm-modal-description-key-${index}`}
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{ fontSize: "15px" }}
                                                    >
                                                        <strong>{formatKey(item?.key)}:</strong>
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontSize: "15px" }}
                                                        id={`confirm-modal-description-value-${index}`}
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        {typeof item?.value === "object" && item?.key === "plan_price"
                                                            ? `$${item?.value?.usd_price}` || "N/A"
                                                            : item?.value || "N/A"}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "grid",
                                                        gridTemplateColumns: "1fr",
                                                        gap: 2,
                                                        mb: 2,
                                                        width: "300px",
                                                        minHeight: 250,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            textAlign: "start",
                                                            fontWeight: "bold",
                                                            mb: 1,
                                                            fontSize: "15px",
                                                        }}
                                                    >
                                                        Payment Slip:
                                                    </Typography>
                                                    <Box
                                                        component="img"
                                                        src={item.value}
                                                        alt="Payment Slip"
                                                        sx={{
                                                            maxWidth: "100%",
                                                            maxHeight: 200,
                                                            objectFit: "contain",
                                                            mb: 2,
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => handlePreviewClick(item.value)}
                                                    />
                                                </Box>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </SoftBox>
                            )
                        )}

                        {!onlyMessage && (
                            <>
                                <SoftButton
                                    variant="outlined"
                                    color="secondary"
                                    onClick={closeHandler}
                                    sx={{ mr: 1 }}
                                >
                                    Cancel
                                </SoftButton>
                                <SoftButton
                                    variant="gradient"
                                    color="primary"
                                    onClick={() => {
                                        if (Array.isArray(description)) {
                                            handleVerifyClick();
                                        } else {
                                            confirmHandler();
                                        }
                                    }}
                                >
                                    {buttonContent}
                                </SoftButton>
                            </>
                        )}
                    </Box>
                </Modal>
            )}

            {/* Second modal */}
            <Modal
                open={isSecondModalOpen}
                onClose={handleSecondModalClose}
                aria-labelledby="second-modal-title"
                aria-describedby="second-modal-description"
                sx={{ outline: "none" }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                        outline: "none",
                    }}
                >
                    <Icon
                        aria-label="close"
                        onClick={closeHandler}
                        sx={{
                            position: "absolute",
                            top: 38,
                            right: 25,
                            color: "text.secondary",
                            cursor: "pointer",
                            fontSize: "20px",
                        }}
                    >
                        <Close />
                    </Icon>
                    <Typography id="preview-modal-title" color="secondary" variant="h4" mb={2}>
                        Verify Payment
                    </Typography>
                    <Typography id="second-modal-title" variant="h6" mb={3}>
                        Are you sure you want to verify the payment?
                    </Typography>
                    <SoftButton
                        variant="outlined"
                        color="secondary"
                        onClick={handleSecondModalClose}
                        sx={{ mr: 1 }}
                    >
                        Cancel
                    </SoftButton>
                    <SoftButton
                        variant="gradient"
                        color="primary"
                        onClick={confirmHandler}
                    >
                        Confirm
                    </SoftButton>
                </Box>
            </Modal>

            {/* Payment Slip Preview Modal */}
            <Modal
                open={isPreviewModalOpen}
                onClose={handlePreviewClose}
                aria-labelledby="preview-modal-title"
                aria-describedby="preview-modal-description"
                sx={{ outline: "none" }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: 600,
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 2,
                        textAlign: "center",
                        outline: "none",
                    }}
                >
                    <Icon
                        aria-label="close"
                        onClick={handlePreviewClose}
                        sx={{
                            position: "absolute",
                            top: 20,
                            right: 20,
                            color: "text.secondary",
                            cursor: "pointer",
                            fontSize: "20px",
                        }}
                    >
                        <Close />
                    </Icon>
                    <Typography id="preview-modal-title" variant="h6" mb={2}>
                        Payment Slip Preview
                    </Typography>
                    <Box
                        component="img"
                        src={previewUrl}
                        alt="Payment Slip Preview"
                        sx={{
                            maxWidth: "100%",
                            maxHeight: "600px",
                            objectFit: "contain",
                            mb: 2,
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
};

ConfirmationModel.propTypes = {
    open: PropTypes.bool.isRequired,
    onlyMessage: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            })
        ),
    ]),
    buttonContent: PropTypes.string,
    closeHandler: PropTypes.func.isRequired,
    confirmHandler: PropTypes.func.isRequired,
};
