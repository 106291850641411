const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? '1px solid #F36523' : '1px solid #E0E0E0',
        borderRadius: '8px',
        height: '46.38px',
        padding: '0 20px',
        color: '#444',
        fontFamily: '"Outfit", sans-serif',
        fontSize: '14px',
        boxShadow: 'none',
        textAlign: 'left',
        '&:hover': {
            borderColor: '#F36523',
        },
    }),
    placeholder: (base) => ({
        ...base,
        fontFamily: '"Outfit", sans-serif',
        fontSize: '14px',
        color: '#aaa',
        textAlign: 'left',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0',
        fontSize: '14px',
        color: '#444',
        textAlign: 'left',
    }),
    singleValue: (base) => ({
        ...base,
        fontSize: '14px',
        color: '#444',
        fontFamily: '"Outfit", sans-serif',
        textAlign: 'left',
    }),
    menu: (base) => ({
        ...base,
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    }),
    option: (base, state) => ({
        ...base,
        fontSize: '14px',
        textAlign: "start",
        color: state.isSelected ? '#fff' : '#444',
        backgroundColor: state.isSelected
            ? '#ff9302'
            : state.isFocused
                ? '#f3652314'
                : '#fff',
        padding: '8px 12px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3652314',
            color: '#16171a',
        },
    }),
};

export default customSelectStyles;
