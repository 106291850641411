import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Pagination, Tooltip, Typography } from "@mui/material";
import "./patient.css";
import { Clear, Delete } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { CONTACT_US_LIST_COLUMNS } from "helper/constant";
import { GetContactUS, deleteUser } from "../../redux/ApiSlice/ContactUSSlice";
import useDebounce from "helper/useDebounce";
import { formatTypography } from "../../utils/index";

function ContactUSComponent() {
  document.title = "Prodigy-chap | Contact us";
  const {
    ContactUS,
    totalContact,
    ContactLoader: staffLoader,
  } = useSelector((state) => state.ContactUS);
  const [openMessageModal, setOpenMessageModel] = useState(false);
  const [staffIdStatus, setStaffIdStatus] = useState({ id: "", status: "" });
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const debounceSearch = useDebounce(search, 1000);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteContactId, setDeleteContactId] = useState("");
  const handleDelete = (id) => {
    setDeleteModal(true)
    setDeleteContactId(id)
  }
  const currentRows = ContactUS?.map((item, index) => ({
    "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
    "date": formatTypography(new Date(item?.created_at).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'numeric',
      year: 'numeric',
    })),
    "Full name": formatTypography(item?.fullname),
    "Email": formatTypography(item?.email),
    "Visa type": formatTypography(item?.visa_type),
    "Country": formatTypography(item?.country),
    "Phone": formatTypography(item?.phone),
    "Chat app": formatTypography(item?.chat_app),
    "Chat App id": formatTypography(item?.chat_app_id),
    "message": (
      <SoftTypography style={{ cursor: "pointer" }} onClick={() => {
        handleDeactiveConfirm(item?.message);
      }} variant="button" fontWeight="medium" color="text">
        View message
      </SoftTypography>
    ),
    "action": (<Box sx={{ gap: "10px", display: "flex" }}>
      <Tooltip title="Delete" placement="top">
        <Icon
          sx={{ cursor: "pointer" }}
          onClick={() => { handleDelete(item?.id) }}
          fontSize="1px"
          style={{ fontSize: "18px" }}
        >
          <Delete />
        </Icon>
      </Tooltip>{" "}

    </Box>)
  }));


  const fetchData = async () => {
    try {
      await dispatch(
        GetContactUS({
          search: debounceSearch,
          page: currentPage, // Send current page
          limit: pageSize,
        })
      );
    } catch (error) { }
  };

  const handleClose = (type) => { 
    if (type === "deactive") {
      setOpenMessageModel(false);
    }
  };

  const handleDeactiveConfirm = (message) => {
    setOpenMessageModel(true);
    setStaffIdStatus({ message });
  };

  const handleDeleteContactConfirm = async () => {
    if (deleteContactId) {
      dispatch(
        deleteUser({ deleteContactId })
      ).then((res) => {
        if (res?.payload?.data?.success) {
          toast.success(
            `contact-us deleted successfully`
          );
          fetchData()
          setDeleteModal(false);
          setDeleteContactId("")
        } else {
          toast.error(SOMETHING_WRONG);
        }
      });
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentPage, debounceSearch]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">Contact Us</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search by name or email"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value?.trimStart());
                      setCurrentPage(1);
                    }}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white"
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <div className="table-size table-sticky-page-data">
              <Table
                columns={CONTACT_US_LIST_COLUMNS}
                rows={currentRows}
                text={"contact"}
                loader={staffLoader}
              />
              </div>
            </SoftBox>
          </Card>
        </SoftBox>
        {totalContact > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalContact / pageSize)} // Total pages calculated from totalStaffs
                page={currentPage} // Current page
                onChange={handlePageChange} // Handle page change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      {openMessageModal && (
        <ConfirmationModel
          open={openMessageModal}
          description={`${staffIdStatus?.message}`}
          title="Message"
          onlyMessage
          closeHandler={() => handleClose("deactive")}
        />
      )}

      {deleteModal && (
        <ConfirmationModel
          open={deleteModal}
          title="Are you sure?"
          description={`You want to delete the contact?`}
          buttonContent={"yes"}
          closeHandler={() => { setDeleteModal(false) }}
          confirmHandler={handleDeleteContactConfirm}
        />
      )}
    </DashboardLayout>
  );
}

export default ContactUSComponent;
