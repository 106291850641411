import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { del } from "api/base";
import { get, post, put } from "api/base";  // Adjust the import for API methods
import { getSession } from "helper/authHelper";
import { roles } from "../../utils/commonStrings";

// Initial state for F1 packages
const initialState = {
  b1b2PackageLoader: false,
  b1b2PackagesInfo: [],
  totalB1B2Packages: 0,
};

// Get the user session to determine role
const userInfo = getSession();

// Thunks for API calls
export const GetAllB1B2Packages = createAsyncThunk(
  "/admin/get-all-b1-b2-packages",
  async ({ page = 0, limit = 0, search }) => {
    try {
      const url = userInfo?.role === roles?.ADMIN || roles?.STAFF ? `/admin/get-all-b1-b2-packages?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}` : `/b1-b2-package/get-all-b1-b2-package?skip=${page}&limit=${limit}${search?`&search=${search}`  :``}`;
      const response = await get(url);
      if (response?.data) {
        return response.data; // Returning the response data if exists
      } else {
        throw new Error("Data not found in response");
      }
    } catch (e) {
      console.error("Error fetching F1 packages:", e);
      return rejectWithValue(e.response?.data || e.message);
    }
  }
);

export const updateB1B2Package = createAsyncThunk("/admin/update-b1-b2-package", async (body) => {
  try {
    const response = await put(`/admin/update-b1-b2-package/${body?.id}`, body?.data);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
});

export const deleteB1B2Package = createAsyncThunk("/admin/delete-b1-b2-package", async (body) => {
  const url = userInfo?.role === roles?.ADMIN || roles?.STAFF ? `/admin/delete-b1-b2-package/${body}` : `/b1-b2-package/delete-b1-b2-package/${body}`
  try {
    const response = await del(url);
    return response;
  } catch (e) {
    return e.response;
  }
});

// Slice for F1 packages
export const B1B2PackageSlice = createSlice({
  name: "b1Packages",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllB1B2Packages.pending, (state) => {
        state.b1b2PackageLoader = true;
      })
      .addCase(GetAllB1B2Packages.fulfilled, (state, action) => {
        state.b1b2PackageLoader = false;
        state.b1b2PackagesInfo = action.payload?.data?.packages || []; 
        state.totalB1B2Packages = action.payload?.data?.total_packages || 0; 
      })
      .addCase(GetAllB1B2Packages.rejected, (state, action) => {
        state.b1b2PackageLoader = false;
      })
      .addCase(updateB1B2Package.pending, (state) => {
        state.b1b2PackageLoader = true;
      })
      .addCase(updateB1B2Package.fulfilled, (state, action) => {
        state.b1b2PackageLoader = false;
      })
      .addCase(updateB1B2Package.rejected, (state) => {
        state.b1b2PackageLoader = false;
      })
      .addCase(deleteB1B2Package.pending, (state) => {
        state.b1b2PackageLoader = true;
      })
      .addCase(deleteB1B2Package.fulfilled, (state, action) => {
        state.b1b2PackageLoader = false;
      })
      .addCase(deleteB1B2Package.rejected, (state) => {
        state.b1b2PackageLoader = false;
      });
  },
});

// Export the reducer for use in the store
export default B1B2PackageSlice.reducer;
