import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import { ConfirmationModel } from "./model/confirmationModel";
import { Box, Grid, Icon, Pagination, Tooltip } from "@mui/material";
import "./patient.css";
import { Clear, Delete, Edit } from "@mui/icons-material";
import SoftInput from "components/SoftInput";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import { B1_B2_PACKAGES_COLUMNS } from "helper/constant";
import useDebounce from "helper/useDebounce";
import { deleteB1B2Package, GetAllB1B2Packages, updateB1B2Package } from "../../redux/ApiSlice/B1B2PackageSlice";
import PackageUpadtaionModal from "./model/packageUpdationModal";
import { formatTypography, validateField } from "../../utils/index";
import { EMPLOYMENT } from "helper/constant";
import { INCOME_LEVEL } from "helper/constant";
import { SPONSERSHIP } from "helper/constant";
import { CHAT_APP } from "helper/constant";

const defaultFormData = {
  name: "",
  email: "",
  employment: "",
  income_level: "",
  sponsorship: "",
  country: "",
  phone_number: "",
  chat_app: "",
  chat_app_id: "",
}

function B1B2PackageComponent() {
  document.title = "Prodigy-chap | B1-B2 Packages";
  const { b1b2PackagesInfo, totalB1B2Packages, b1b2PackageLoader } = useSelector(
    (state) => state.b1Packages
  );
  const [openDeleteB1B2Modal, setOpenDeleteB1B2Modal] = useState(false);
  const [B1B2DeleteId, setDeleteB1B2Id] = useState(null);

  const [updateB1B2PackageData, setUpdateB1B2PackageData] = useState({ id: "", data: {} });
  const [openB1B2PakageModal, setOpenB1B2PakageModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 1000);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [pageSize] = useState(10); // Page limit
  const currentRows = b1b2PackagesInfo?.map((item, index) => ({
    "sr": formatTypography(index + 1 + (currentPage - 1) * pageSize),
    "date": formatTypography(new Date(item?.created_at).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'numeric',
      year: 'numeric',
    })),
    "name": formatTypography(item?.name),
    "email": formatTypography(item?.email),
    "employment": formatTypography(item?.employment),
    "income": formatTypography(item?.income_level),
    "sponsorship": formatTypography(item?.sponsorship),
    "country": formatTypography(item?.country),
    "phone": formatTypography(item?.phone_number),
    "chat app": formatTypography(item?.chat_app),
    "chat id": formatTypography(item?.chat_app_id),
    "action": (
      <Box sx={{ gap: "10px", display: "flex" }}>
        {" "}
        <Tooltip title="Edit" placement="top">
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleEdit(item?.id, item);
            }}
            fontSize="1px"
            style={{ fontSize: "18px" }}
          >
            <Edit />
          </Icon>
        </Tooltip>{" "}
        <Tooltip title="Delete" placement="top">
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleDeleteB1B2Confirm(item?.id);
            }}
            fontSize="1px"
            style={{ fontSize: "18px" }}
          >
            <Delete />
          </Icon>
        </Tooltip>
      </Box>
    ),
  }));

  const fetchData = async () => {
    try {
      await dispatch(GetAllB1B2Packages({
        search: debounceSearch,
        page: currentPage, 
        limit: pageSize,
      }));
    } catch (error) {}
  };

  const handleEdit = (id, data) => {
    setFormData({
      name: data?.name || "",
      email: data?.email || "",
      employment: data?.employment || "",
      income_level: data?.income_level || "",
      sponsorship: data?.sponsorship || "",
      country: data?.country || "",
      phone_number: data?.phone_number || "",
      chat_app: data?.chat_app || "",
      chat_app_id: data?.chat_app_id || "",
    });
    setUpdateB1B2PackageData({ id, data: formData });
    setOpenB1B2PakageModal(true);
  };

  const handleClose = (isDelete) => {
    if (isDelete) {
      setOpenDeleteB1B2Modal(false);
    } else {
      setOpenB1B2PakageModal(false);
    }
  };

  const handleChange = (e, name) => {
    const { target } = e;
    const key = name || target.name;
    const value = target?.value || e.value; 
    setFormData((prev) => ({ ...prev, [key]: value }));

    if (errors[`${key}Err`]) {
      setErrors((prev) => ({ ...prev, [`${key}Err`]: "" }));
    }
  };

  const handleUpdateSubmit = async () => {
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) newErrors[`${key}Err`] = error;
    });
  
    if (!formData.name) newErrors.nameErr = "Name is required.";
    if (!formData.email) newErrors.emailErr = "Email is required.";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await dispatch(updateB1B2Package({
        id: updateB1B2PackageData?.id,
        data: formData
      }));

      if (response?.payload?.success) {
        setOpenB1B2PakageModal(false);
        toast.success(response?.payload?.message);
        fetchData();
      } else {
        toast.success(SOMETHING_WRONG);
        setOpenB1B2PakageModal(false);
      }
    } catch (error) { }
  };

  const handleDeleteB1B2Confirm = (id) => {
    setDeleteB1B2Id(id);
    setOpenDeleteB1B2Modal(true);
  };

  const handleConfirmDelete = async () => {
    if (B1B2DeleteId) {
      const response = await dispatch(deleteB1B2Package(B1B2DeleteId));
      setOpenDeleteB1B2Modal(false);
      if (response?.payload?.data?.success) {
        toast.success(`B1-B2 Package deleted successfully`);
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, debounceSearch]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">B1-B2 Packages List</SoftTypography>
                <div style={{ position: "relative", marginLeft: "15px" }}>
                  <SoftInput
                    placeholder="Search name, email or phone"
                    icon={{
                      component: "search",
                      direction: "left",
                    }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value?.trimStart());
                      setCurrentPage(1);
                    }}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  {search && (
                    <Icon
                      onClick={() => setSearch("")}
                      style={{
                        position: "absolute",
                        right: 6,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        backgroundColor: "white"
                      }}
                      fontSize="1px"
                    >
                      <Clear />
                    </Icon>
                  )}
                </div>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <div className="table-size table-sticky-page-data">
                <Table
                  columns={B1_B2_PACKAGES_COLUMNS}
                  rows={currentRows}
                  text={"b1-b2 packages"}
                  loader={b1b2PackageLoader}
                />
              </div>
            </SoftBox>
          </Card>
        </SoftBox>
        {totalB1B2Packages > pageSize && (
          <Grid container spacing={3} marginTop="20px">
            <Grid xs={12} display="flex" justifyContent="end">
              <Pagination
                count={Math.ceil(totalB1B2Packages / pageSize)} 
                page={currentPage}
                onChange={handlePageChange} change
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        )}
      </SoftBox>

      {
        openB1B2PakageModal && <PackageUpadtaionModal
          open={openB1B2PakageModal}
          title="Update B1-B2 Package"
          formData={formData}
          fields={[
            { name: "name", label: "Name", type: "text", placeholder: "Enter name", required: true },
            { name: "email", label: "Email", type: "text", placeholder: "Enter email", required: true },
            { name: "employment", options: EMPLOYMENT, label: "Employment", type: "dropdown", placeholder: "Enter employment", required: true },
            { name: "income_level", options: INCOME_LEVEL, label: "Income", type: "dropdown", placeholder: "Enter income level", required: true },
            { name: "sponsorship", options: SPONSERSHIP, label: "Sponsorship", type: "text", placeholder: "Enter sponsorship details", required: true },
            { name: "country", label: "Country", type: "countryDropdown", isCountryDropdown: true, required: true },
            { name: "phone_number", label: "Phone", type: "phone", placeholder: "Enter phone number", required: true },
            { name: "chat_app", options: CHAT_APP, label: "Chat App", type: "dropdown", placeholder: "Enter chat app", required: true },
            { name: "chat_app_id", label: "Chat ID", type: "text", placeholder: "Enter chat ID", required: true },
          ]}
          errors={errors}
          onClose={() => handleClose(false)}
          onChange={handleChange}
          onSubmit={handleUpdateSubmit}
        />
      }

      {openDeleteB1B2Modal && (
        <ConfirmationModel
          open={openDeleteB1B2Modal}
          title="Are you sure?"
          description={`You want to delete the B1-B2 package?`}
          buttonContent={"Delete"}
          closeHandler={() => handleClose(true)}
          confirmHandler={handleConfirmDelete}
        />
      )}
    </DashboardLayout>
  );
}

export default B1B2PackageComponent;
