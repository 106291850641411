import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ConfirmationModel } from "./model/confirmationModel";
import { Icon } from "@mui/material";
import "./patient.css";
import SoftButton from "components/SoftButton";
import { Add, Category } from "@mui/icons-material";
import {
  getAllTrainingPlans,
  createTrainingPlan,
  updateTrainingPlan,
  deleteTrainingPlan
} from "../../redux/ApiSlice/TrainingPlanSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { SOMETHING_WRONG } from "helper/constant";
import "./TrainingPlan.css"
import PlanModal from "./model/planModal";
import { PLAN_FIELDS } from "helper/constant";
import SoftTypography from "components/SoftTypography";

const initialState = {
  "plan_name": "",
  "usd_price": "",
  "kyat_price": "",
  "baht_price": "",
  "plan_description": [],
  "category": ""
}

function TrainingPlan() {
  document.title = "Prodigy-chap | Training Plans";
  const { trainingPlanInfo, totalTrainingPlans, trainingPlanLoader } = useSelector((state) => state.trainingPlan);
  const [addForm, setAddForm] = useState(initialState);
  const [updateForm, setUpdateForm] = useState(initialState);
  const [errorAddTrainingPlan, setErrorAddTrainingPlan] = useState({});
  const [errorUpdateTrainingPlan, setErrorUpdateTrainingPlan] = useState({});
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteTrainingPlanId, setDeleteTrainingPlanId] = useState(null);
  const [openAddTrainingPlan, setOpenAddTrainingPlan] = useState(false);
  const [openUpdateTrainingPlan, setOpenUpdateTrainingPlan] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    dispatch(
      getAllTrainingPlans()
    );
  }, [dispatch]);

  const handleEdit = useCallback((id, planData) => {
    const updatedFormData = Object.keys(initialState).reduce((acc, key) => {
      acc[key] = planData[key] || initialState[key]; 
      return acc;
    }, {});
  
    setUpdateForm((prev) => ({
      ...prev,
      ...updatedFormData,
      id: id
    }));
  
    setOpenUpdateTrainingPlan(true);
  }, []);

  const handleModalClose = (modalType) => {
    if (modalType === "addTrainingPlan") {
      setOpenAddTrainingPlan(false);
      setAddForm(initialState);
      setErrorAddTrainingPlan({});
    }
    if (modalType === "updateTrainingPlan") {
      setOpenUpdateTrainingPlan(false);
      setUpdateForm(initialState);
      setErrorUpdateTrainingPlan({});
    };
    if (modalType === "delete") setOpenDeleteModel(false);
  };

  const handleChange = useCallback((e, isUpdate) => {
    const { name, value } = e.target;
    if (isUpdate) {
      setUpdateForm((prev) => ({ ...prev, [name]: value }));
      setErrorUpdateTrainingPlan((prev) => ({ ...prev, [`${name}Err`]: "" }));
      if (name === "plan_description") {
        setErrorUpdateTrainingPlan((prev) => ({ ...prev, plan_descriptionErr: "" })); // Clear error for plan_description
      }
    } else {
      if (name === "plan_description") {
        setAddForm((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setAddForm((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setErrorAddTrainingPlan((prev) => ({
        ...prev,
        [`${name}Err`]: "",
      }));
    }
  }, []);

  const handleAction = (type, id) => {
    switch (type) {
      case "delete":
        setOpenDeleteModel(true);
        setDeleteTrainingPlanId(id);
        break;
      default:
        break;
    }
  };

  const handleConfirmAction = async (actionType) => {
    if(actionType === "delete" && deleteTrainingPlanId) {
      const response = await dispatch(deleteTrainingPlan(deleteTrainingPlanId));
      setOpenDeleteModel(false);
      if (response?.payload?.data?.success) {
        toast.success("Training Plan deleted successfully");
        fetchData();
      } else {
        toast.error(SOMETHING_WRONG);
      }
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.plan_name) errors.plan_nameErr = "Plan name is required";
    if (!formData.usd_price) errors.usd_priceErr = "USD price is required";
    if (!formData.kyat_price) errors.kyat_priceErr = "Kyat Price is required";
    if (!formData.baht_price) errors.baht_priceErr = "Baht Price is required";
    if (!formData.plan_description.length > 0) errors.plan_descriptionErr = "Plan descripton is required";
    if (!formData.category) errors.categoryErr = "Plan category is required";
    return errors;
  };

  const handleSubmit = (isUpdate) => {
    const formData = isUpdate ? updateForm : addForm;
    const setError = isUpdate ? setErrorUpdateTrainingPlan : setErrorAddTrainingPlan;

    const errors = validateForm(formData);
    setError(errors);
    if (Object.keys(errors).length > 0) {
      return 
    }
    if (isUpdate) {
        dispatch(updateTrainingPlan(updateForm)).then((res) => {
          if (res?.payload?.success) {
            toast.success("Training Plan updated successfully.");
            setOpenUpdateTrainingPlan(false);
            setUpdateForm(initialState);
            fetchData();
          } else {
            toast.error(SOMETHING_WRONG);
          }
        });
    } else {
        dispatch(
          createTrainingPlan(addForm)
        ).then((res) => {
          if (res?.payload?.success) {
            toast.success("Training Plan created successfully.");
            setOpenAddTrainingPlan(false);
            setAddForm(initialState); 
            fetchData();
          }else{
            toast.error(SOMETHING_WRONG);
          }
        });
    }
  };

  const handleKeyDown = (e, isUpdate) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isUpdate) {
        handleSubmit(true);
      } else {
        handleSubmit(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h5" sx={{marginLeft: "20px"}}>Total Plans: {totalTrainingPlans}</SoftTypography>
              <SoftBox onClick={() => setOpenAddTrainingPlan(true)} style={{ cursor: "pointer" }}>
                <SoftButton
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="outlined"
                  color="primary"
                  style={{marginRight: "20px"}}
                >
                  Add Plan
                  <Icon fontWeight="medium" style={{ marginLeft: "5px" }}>
                    <Add />
                  </Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <div className="planPricingBox">
              <div className="container">
                <div className="boxalignment">
                  {trainingPlanInfo?.map((item, index) => {
                    return (<div className="box" key={index}>
                      <div>
                        <h2>
                          {item?.plan_name}
                        </h2>
                        <h3>
                          {`$${item?.usd_price}`}
                        </h3>
                        <p>
                          {`(${item?.kyat_price} Kyat / ${item?.baht_price} Baht)`}
                        </p>
                        <p style={{fontSize: "16px"}}>
                          Package Category: {`${item?.category}` || "-"}
                        </p>
                        <ul>
                          {item?.plan_description?.map((description, index) => {
                            return (
                              <li key={index}>
                                {description}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      {<div className="buttonAlignment">
                        <SoftButton sx={{marginRight: "10px"}} onClick={() => handleEdit(item?.id, item)}>Edit</SoftButton>
                        <SoftButton sx={{marginLeft: "10px"}} onClick={() => handleAction("delete", item?.id)}>Delete</SoftButton>
                      </div>}
                    </div>)
                  })}
                </div>
              </div>
            </div>
          </Card>
        </SoftBox>
      </SoftBox>
      
      {openAddTrainingPlan && <PlanModal
        open={openAddTrainingPlan}
        onClose={() => handleModalClose("addTrainingPlan")}
        title={"Add Training Plan"}
        fields={PLAN_FIELDS}
        formData={addForm}
        onChange={handleChange}
        onSubmit={() => handleSubmit(false)}
        errors={errorAddTrainingPlan}
      />}

      {openUpdateTrainingPlan && <PlanModal
        open={openUpdateTrainingPlan}
        onClose={() => handleModalClose("updateTrainingPlan")}
        title={"Update Training Plan"}
        fields={PLAN_FIELDS}
        formData={updateForm}
        errors={errorUpdateTrainingPlan}
        onChange={(e) => handleChange(e, true)}
        onSubmit={() => handleSubmit(true)}
      />}

      {openDeleteModel && (
        <ConfirmationModel
          open={openDeleteModel}
          title="Are you sure?"
          description={`You want to delete the training plan?`}
          buttonContent={"Delete"}
          closeHandler={() => handleModalClose("delete")}
          confirmHandler={() => handleConfirmAction("delete")}
        />
      )}
    </DashboardLayout>
  );
}

export default TrainingPlan;
